<template lang="html">
  <div class="blogedit">
    <div v-if="loading" class="overlay">
      <i class="fas fa-spinner fa-pulse"></i> 加载中…
    </div>
    <div v-else class="card card-primary card-outline box-primary">
      <div class="overlay" v-if="$store.state.loading">
        <i class="fas fa-spinner fa-pulse"></i>
      </div>
      <div class="card-header p-1">
        <h3 class="card-title">博文编辑</h3>
      </div>
      <div class="card-body p-1">
        <div class="form-group has-feedback">
          <label for="Title">标题</label>
          <input type="text" class="form-control" id="Title" :class="{ 'is-invalid': $v.form.title.$error }" v-model.trim="$v.form.title.$model" placeholder="请输入标题">
          <div class="invalid-feedback">
            <span v-if="!$v.form.title.required">请输入标题</span>
            <span v-if="!$v.form.title.minLength">标题至少 {{$v.form.title.$params.minLength.min}} 个字符</span>
            <span v-if="!$v.form.title.maxLength">标题最多 {{$v.form.title.$params.maxLength.max}} 个字符</span>
          </div>
        </div>
        <div class="form-group">
          <label for="Text">简介</label>
          <!--<textarea class="form-control" id="Text" v-model.trim="form.text" placeholder="请输入简介"></textarea>-->
          <PellEditor v-model.trim="form.text"
                      :options="options"
                      :buttons="['bold','italic','underline','strikethrough','sub','sup','olist','ulist','removeFormat',]" />
        </div>
        <div>
          <CollapedBox ref="baseSetting" maximizeIcon>
            <template v-slot:header>
              基本设置
            </template>
            <div class="form-group custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitchLittlePlanetIntro" v-model.trim="form.featured">
              <label class="custom-control-label" for="精选">精选</label>
            </div>
            <div class="form-group">
              <button class="btn btn-outline-primary mb-1" @click="showAudioLibDialog">选择音乐</button>{{(form.audio||{}).name||null}}
              <audio class="form-control" :src="(form.audio||{}).url||null" controls></audio>
            </div>
            <div class="form-group custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitchLittlePlanetIntro" v-model.trim="form.audioAutoPlay">
              <label class="custom-control-label" for="自动播放">自动播放</label>
            </div>
            <div class="form-group">
              <button class="btn btn-outline-primary"> 切换皮肤：{{form.skin||'默认皮肤'}} </button>
            </div>
            <div class="form-group">
              <label for="panoTitle">公共标签</label>
              <CheckBox checkprop="name"
                        :checkList="publicTags"
                        :selected="form.tags"
                        :onChange="tagsCheckOnChange">
              </CheckBox>
            </div>
            <div class="form-group">
              <label for="panoTitle">私有标签</label>
              <CheckBox checkprop="name"
                        :checkList="privateTags"
                        :selected="form.tags"
                        :onChange="tagsCheckOnChange">
              </CheckBox>
            </div>
            <div class="form-group">
              <button class="btn btn-outline-primary" @click="$refs.selectMapPoint.showModal()">选择地点</button>
              <div v-if="form.gpsLng!=0||form.gpsLat!=0">
                <MapView :mark="{lat:form.gpsLat,lng:form.gpsLng}"></MapView>
              </div>
            </div>
            <div class="form-group custom-control custom-switch">
              <input type="checkbox" class="custom-control-input" id="customSwitchLittlePlanetIntro" v-model.trim="form.closed">
              <label class="custom-control-label" for="隐藏博文">隐藏博文</label>
            </div>
            <MapSelectedPointModal ref="selectMapPoint" :markerPoint="{lat: form.gpsLat,lng:form.gpsLng}" @submit="changeMarker">
            </MapSelectedPointModal>
          </CollapedBox>
        </div>
        <div v-if="form.items.length<$v.form.items.$params.maxLength.max" class="text-center mb-1">
          <button class="btn btn-outline-primary px-4 py-1" v-if="addindex!=0" @click="addindex=0"> 添加 </button>
          <div v-else>
            <div class="btn-group" role="group" aria-label="First group">
              <template v-for="(i,typeindex) in itemTypes">
                <template v-if="i.value == 'Pano' || i.value == 'Image' || i.value == 'Video'">
                  <button type="button" class="btn btn-outline-primary p-1" @click="addItemStart(addindex,i.value)">{{i.key}}</button>
                </template>
              </template>
            </div>
          </div>
        </div>
        <template v-for="(i,index) in $v.form.items.$each.$iter">

          <div class="card" v-if="selectedIndex==index">
            <div class="card-header bg-primary py-1">
              <div class="card-title">
                {{getItemTypekey(i.$model.type)}}内容设置
              </div>
              <div class="card-tools">
                <button type="button" class="btn btn-tool" @click="selectedIndex=null">
                  <i class="fa fa-minus"></i>
                </button>
                <button type="button" class="btn btn-tool" @click="removeItem(index)">
                  <i class="fas fa-times"></i>
                </button>
              </div>

            </div>
            <div class="card-body p-1">
              <div class="form-group has-feedback">
                <label for="Title">
                  标题
                </label>
                <input type="text" class="form-control" id="Title" v-model.trim="i.$model.title" placeholder="请输入标题">
                <!--<span class="text-danger" v-if="!i.title.required">请输入标题</span>
                <span class="text-danger" v-else-if="!i.title.minLength">至少 {{i.link.$params.minLength.min}} 个字符</span>-->
                <span class="text-danger" v-if="!i.title.maxLength">最多 {{i.link.$params.maxLength.max}} 个字符</span>
                <!--<span class="text-danger" v-else-if="!i.title.isLink">格式不正确</span>-->
              </div>
              <div class="form-group">
                <label for="panoText">内容</label>
                <!--<textarea type="text" class="form-control" id="panoText" placeholder="输入内容" v-model:text="i.$model.description"></textarea>-->
                <PellEditor v-model="i.$model.description"
                            :options="options"
                            :buttons="['bold','italic','underline','strikethrough','sub','sup','olist','ulist','removeFormat',]" />
              </div>
              <div>
              </div>
              <!--<button type="button" class="btn btn-primary" @click="showFileLibDialog(index)">选择素材</button>-->
              <div v-if="i.$model.file&&i.$model.file.isImage" style="max-height:500px;max-width:600px">
                <LibImageCard :item="i.$model.file">
                </LibImageCard>
              </div>
              <div v-if="i.$model.file&&i.$model.file.isVideo" style="max-height:500px;max-width:600px">
                <LibVideoCard :item="i.$model.file">
                </LibVideoCard>
              </div>
              <div v-if="i.$model.file&&i.$model.file.isAudio" style="max-height:500px;max-width:600px">
                <audio :src="i.$model.file.url" controls="controls" controlslist="nodownload" ref="audioPlayer" style="height:30px;max-width:100%"></audio>
              </div>
              <!--<div class="info-box-icon bg-gray itemicon" v-if="i.$model.linkItem&&i.$model.linkItem.firstImage"><div class="imgdiv" :style="makeUrlStyle((i.$model.linkItem.firstImage||{url:''}).url)"></div></div>-->
              <div v-if="i.$model.linkItem" style="max-height:500px;max-width:100%;height:50vh">
                <Krpano ref="Pano" :pano-id="i.$model.linkItem.guid" height="100%" :krpanoSettings="krpanoSettings" :on-krpano-ready="setContextmenu" :sceneVars="sceneVars">
                </Krpano>
              </div>
              <div v-if="i.$model.type=='Link'" class="form-group has-feedback">
                <label for="url">链接</label>
                <input v-if="$inRole('管理员')||$inRole('VIP')" class="form-control" type="url" v-model.trim="i.$model.url" placeholder="请输入 Url 地址" />
                <input v-else class="form-control" value="需要开通 VIP" disabled />
                <!--<span class="text-danger" v-if="!i.url.required">请输入 Url 地址</span>
                <span class="text-danger" v-else-if="!i.url.minLength">至少 {{i.link.$params.minLength.min}} 个字符</span>-->
                <span class="text-danger" v-if="!i.url.maxLength">最多 {{i.link.$params.maxLength.max}} 个字符</span>
                <span class="text-danger" v-else-if="!i.url.isUrl">格式不正确</span>
              </div>
              <div v-if="i.$model.type=='Location'" style="height:120px;margin-bottom:15px;position:relative">
                <div v-if="i.$model.gpsLng!=0||i.$model.gpsLat!=0">
                  <MapView :mark="{lat:i.$model.gpsLat,lng:i.$model.gpsLng}"></MapView>
                </div>
              </div>
            </div>
          </div>
          <div class="card" v-else>
            <div class="card-header py-1" :class="{ 'bg-danger': i.$invalid, 'bg-gray': !i.$invalid }">
              <div class="card-title text-truncate" style="max-width: calc(100% - 60px);">
                {{i.$model.title&&i.$model.title!=''?i.$model.title:'无标题'}}&nbsp;
              </div>
              <div class="card-tools">
                <button type="button" class="btn btn-tool" @click="selectedIndex=index" title="展开">
                  <i class="fa fa-plus"></i>
                </button>
                <button type="button" class="btn btn-tool" @click="$refs.deleteItem.show=true;deleteIndex=index" title="删除">
                  <i class="fas fa-times"></i>
                </button>

              </div>
            </div>
            <!-- /.card-header -->
            <div class="card-body p-0" @click.stop="selectedIndex=index" style="cursor:pointer">
              <div style="display:flex;justify-content:space-around;align-items:flex-start">
                <div class="itemicon" style="flex:none;margin:5px;align-self:center;color:#fff">
                  <div v-if="i.$model.type=='Pano'" class="imgdiv" :style="makeUrlStyle((((i.$model.linkItem||{}).firstImage||{url:''}).url||'').replace('/0/0/0/0/', '/200/0/0/0/'))">
                    <i class="icon-panorama drop-shadow"></i>
                  </div>
                  <div v-if="i.$model.type=='Image'" class="imgdiv" :style="makeUrlStyle(i.$model.file.url.replace('/0/0/0/0/', '/200/0/0/0/'))">
                    <i class="fas fa-image text-shadow"></i>
                  </div>
                  <div v-if="i.$model.type=='Text'" class="imgdiv">
                    <i class="fas fa-align-justify text-shadow"></i>
                  </div>
                  <div v-if="i.$model.type=='Audio'" class="imgdiv">
                    <i class="fas fa-music text-shadow"></i>
                  </div>
                  <div v-if="i.$model.type=='Video'" class="imgdiv" :style="makeUrlStyle(i.$model.file.url.replace('/0/0/0/0/', '/200/0/0/0/'))">
                    <i class="fas fa-video text-shadow"></i>
                  </div>
                  <div v-if="i.$model.type=='Link'" class="imgdiv">
                    <i class="fas fa-link text-shadow"></i>
                  </div>
                  <div v-if="i.$model.type=='Location'" class="imgdiv">
                    <i class="fas fa-map-marked-alt text-shadow"></i>
                  </div>
                </div>
                <div v-html="i.$model.description?i.$model.description:'无内容'" style="flex:auto;margin-top:5px;max-height: 75px;overflow: hidden;text-overflow: ellipsis;display: -webkit-box;-webkit-line-clamp: 3;-webkit-box-orient: vertical;">
                </div>
                <div style="flex:none;margin:5px;align-self:center">
                  <div class="btn-group-vertical" role="group" aria-label="First group">
                    <button type="button" class="btn btn-outline-primary" @click.stop="moveItem(index,index-1)">上移</button>
                    <button type="button" class="btn btn-outline-primary" @click.stop="moveItem(index,+index+1)">下移</button>
                  </div>
                </div>
              </div>
            </div>
            <!-- /.card-body -->
          </div>

          <div v-if="form.items.length<$v.form.items.$params.maxLength.max" class="text-center mb-1">
            <button class="btn btn-outline-primary px-4 py-1" v-if="addindex!=+index+1" @click="addindex=+index+1"> 添加 </button>
            <div v-else>
              <div class="btn-group" role="group" aria-label="First group">
                <button type="button" class="btn btn-outline-primary p-1" v-for="(i,typeindex) in itemTypes" @click="addItemStart(addindex,i.value)">{{i.key}}</button>
              </div>
            </div>
          </div>
        </template>
        <div v-show="blogViewShow">
          <CollapedBox ref="blogView" maximizeIcon @maximized="blogViewmaximized" @minimized="blogViewminimized">
            <template v-slot:header>
              博文预览
            </template>
            <component v-if="blogShow" :is="blogComp" :blog="form"></component>
            <div style="position:fixed;bottom:0;left:0;right:0;display:flex;justify-content:center;">
              <div style="max-width: 660px;overflow-x:auto;background-color:#fff;display:flex;box-shadow:0 0 3px 1px #000;">
                <div :class="{selected:form.skin==''}" style="padding:10px;flex:none;cursor:pointer;" @click="form.skin='';render();">默认</div>
                <div v-for="i in skins" :class="{selected:form.skin==i.name}" style="padding:10px;flex:none;cursor:pointer;" @click="form.skin=i.name;render();">{{i.name}}</div>
              </div>
            </div>
          </CollapedBox>
        </div>
      </div>
      <!--<div class="card-footer p-1">
          <button type="button" class="btn btn-default" @click.prevent="Close">取消</button>
          <template v-if="form.guid==null||form.guid.length==0">
              <button type="button" @click.prevent="Create" class="btn btn-primary" :disabled="updateing">添加</button>
          </template>
          <template v-else>
              <button type="button" @click.prevent="Update(form.guid)" class="btn btn-primary" :disabled="updateing">更新</button>
          </template>
          <button type="button" class="btn btn-default" @click.prevent="$refs.baseSetting.toggleMaximize()">设置</button>
          <button type="button" class="btn btn-default" @click.prevent="$refs.blogView.toggleMaximize()">预览</button>
      </div>-->

    </div>
    <MapSelectedPointModal ref="newLocation" @submit="creatLocation">
    </MapSelectedPointModal>
    <SimpleModal ref="deleteItem" :zIndex="1040">
      <div>
        <h5>删除项目</h5>
      </div>
      确定删除"{{deleteIndex!=null?form.items[deleteIndex].title:''}}"？
      <div style="display:flex;justify-content:flex-end;">
        <button type="button" class="btn btn-primary btn-sm mr-2" @click="$refs.deleteItem.show=false;deleteIndex=null;">取消</button>
        <button class="btn btn-danger btn-sm" @click="removeItem(deleteIndex);$refs.deleteItem.show=false;deleteIndex=null;">删除</button>
      </div>
    </SimpleModal>
    <AudioLib ref="AudioLib" libUrl="/Api/My/Audios" tagUrl="/Api/My/Tags/All" :onSelected="AudioLibSelected"></AudioLib>
    <FileLib ref="FileMultipleLib" :multiple="true" libUrl="/Api/My/Libs" panosUploadUrl="/Api/My/Panos/Upload" filesUploadUrl="/Api/My/Files/Upload" tagsUrl="/Api/My/Tags/All" fileUrl="/Api/My/Files" panoUrl="/Api/My/Panos" :withType="FileMultipleLibType" :onSelected="FileLibSelected" @submit="FileMultipleLibOnSubmit"></FileLib>
    <PanoLib ref="PanoMultipleLib" :multiple="true" :libUrl="libPanosUrl" @submit="PanoMultipleLibOnSubmit"></PanoLib>
    <BreakLeave v-if="cantleave"></BreakLeave>
    <Footer v-if="!loading">
      <button type="button" class="btn btn-sm btn-danger mr-2" @click.prevent="cancel">取消</button>
      <button type="button" class="btn btn-sm btn-default mr-2" @click.prevent="$refs.baseSetting.toggleMaximize()">设置</button>
      <button type="button" class="btn btn-sm btn-default mr-2" @click.prevent="$refs.blogView.toggleMaximize()">预览</button>
      <template v-if="form.guid==null||form.guid.length==0">
        <button type="button" class="btn btn-sm btn-warning mr-2" @click.prevent="form.private=true;Create()" :disabled="updateing">暂存</button>
        <button type="button" class="btn btn-sm btn-primary" @click.prevent="form.private=false;Create()" :disabled="updateing">发布</button>
      </template>
      <template v-else>
        <button type="button" class="btn btn-sm btn-warning mr-2" @click.prevent="form.private=true;Update(form.guid)" :disabled="updateing">暂存</button>
        <button type="button" class="btn btn-sm btn-primary" @click.prevent="form.private=false;Update(form.guid)" :disabled="updateing">发布</button>
      </template>
    </Footer>
  </div>
</template>

<script>
  import {
    CollapedBox,
    CheckBox,
    FileLib,
    PanoLib,
    BreakLeave,
    AudioLib,
    Footer,
    MapSelectedPointModal,
    PellEditor,
    SimpleModal,
    LibImageCard,
    LibVideoCard,
    MapView,
    Krpano
  } from 'common'
  import skinList from 'common/src/components/BlogSkins/SkinList.json'
  import { required, minLength, maxLength, helpers } from 'vuelidate/lib/validators'
  const isMobile = helpers.regex('isMobile', /^1(3|4|5|6|7|8|9)\d{9}$/)
  const isTel = helpers.regex('isTel', /^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{8}$/)
  const isUrl = helpers.regex('isUrl', /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/)

  export default {
    components: {
      CollapedBox,
      CheckBox,
      FileLib,
      PanoLib,
      BreakLeave,
      AudioLib,
      Footer,
      MapSelectedPointModal,
      PellEditor,
      SimpleModal,
      LibImageCard,
      LibVideoCard,
      MapView,
      Krpano
    },
    data() {
      return {
        cantleave: true,
        publicTags: [],
        privateTags: [],
        skins: skinList,
        addindex: null,
        selectedIndex: null,
        deleteIndex: null,
        form: {
          guid: null,
          title: '',
          text: '',
          featured: false,
          skin: '',
          closed: false,
          audioAutoPlay: false,
          private: false,
          introImage: null,
          tags: [],
          items: [],
          cover: null,
          audio: null,
          user: {},
          gpsLat: 0,
          gpsLng: 0,
          time: '尚未'
        },
        itemTypes: [{ key: '全景', value: 'Pano' }, { key: '图片', value: 'Image' }, { key: '文本', value: 'Text' }, { key: '音频', value: 'Audio' }, { key: '视频', value: 'Video' }, { key: '链接', value: 'Link' }, { key: '定位', value: 'Location' }],
        loading: false,
        FileMultipleLibOnSubmit: () => { },
        PanoMultipleLibOnSubmit: () => { },
        FileMultipleLibType: null,
        blogComp: null,
        blogShow: false,
        updateing: false,
        blogViewShow: false,
        showMap: false,
        showNewLocation: false,
        options: {
          whiteList: {
            p: [],
            b: [],
            strong: [],
            i: [],
            u: [],
            strike: [],
            br: [],
            //h1: [],
            //h2: [],
            //blockquote: [],
            //hr: [],
            ol: [],
            ul: [],
            li: [],
            //code: [],
            //a: ['href'],
            //img: ['src'],
            sub: [],
            sup: [],
          },
          stripIgnoreTag: true,      // 过滤所有非白名单标签的HTML
          stripIgnoreTagBody: ['script'] // script标签较特殊，需要过滤标签中间的内容
        },
        krpanoSettings: {
          mwheel: false,
          //passQueryParameters: true
          vars: {
            'preview.type': 'grid(CUBE,64,64,512,0x000000,0x000000,0x000000);',
            'autorotate.enabled': true,
            'autorotate.speed': 7,
            'autorotate.accel': 1,
            'autorotate.waittime': 5
          }
        },
        sceneVars: {
          'view.keep': true,
          keep: true,
        },
        libPanosUrl: window.global.ENABLE_ELASTICSEARCH ? `/Api/ES/My/Panos` : `/Api/My/Panos`,
      }
    },
    validations: {
      form: {
        title: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(50)
        },
        items: {
          required,
          //minLength: minLength(2),
          maxLength: maxLength(100),
          $each: {
            title: {
              maxLength: maxLength(50)
            },
            url: {
              isUrl: (value, p) => {
                if (p.type == 'Link') {
                  if (value) {
                    return /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}(\.[a-zA-Z0-9()]{1,6})\b(:[0-9]{1,5})?([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(value)
                  } else {
                    return false
                  }
                }
                return true
              },
              maxLength: maxLength(256)
            }
          }

        },
      }
    },
    created() {
      this.loadData()
      this.loadTags()
    },
    mounted() {
      this.render()
    },
    computed: {
    },
    watch: {
    },
    methods: {
      render() {
        var name = this.form.skin
        if (!name || name.length == 0) {
          name = 'Default'
        }
        this.blogComp = () =>
          ({
            component: import(`common/src/components/BlogSkins/${name}.vue`),
            loading: { template: '<div style="height: 100%; width: 100%; display: table;"><div style="display: table-cell; vertical-align: middle; text-align: center;"><div>加载中</div></div></div>' },
            error: Default,
            delay: 200,
            timeout: 10000
          })
      },
      blogViewmaximized() {
        this.form.user = this.$store.state.user.main
        this.blogShow = true;
        this.blogViewShow = true;
        this.render()
      },
      blogViewminimized() {
        this.blogShow = false;
        this.blogViewShow = false;
      },
      changeMarker(p) {
        this.form.gpsLat = p.lat
        this.form.gpsLng = p.lng
      },
      getItemTypekey(value) {
        for (var i in this.itemTypes) {
          if (this.itemTypes[i].value == value)
            return this.itemTypes[i].key
        }
      },
      loadData() {
        if (this.$route.params.blogId) {
          this.loading = true
          this.$axios.get('/Api/My/Blogs/' + this.$route.params.blogId)
            .then(res => {
              this.form = res.data;
              this.form.time = '尚未'
              this.loading = false
            })
        }
      },
      addItem(index, type, file) {
        if (this.form.items.length >= this.$v.form.items.$params.maxLength.max) {
          this.$message({
            message: `最多只能添加 ${this.$v.form.items.$params.maxLength.max} 项`,
            type: 'info',
            zIndex: 9999,
          })
          return
        }
        var item = this.newItem()
        item.type = type
        if (type == 'Pano') {
          item.linkItem = file;
          item.title = file.title
        } else {
          item.file = file;
        }
        if (file && file.name) {
          item.title = file.name
        }
        console.log(index);
        this.form.items.splice(index, 0, item)
        this.selectedIndex = index;
        return item
      },
      creatLocation(p) {
        if (p.lat == 0 && p.lng == 0) {
          return
        }
        var item = this.addItem(+this.addindex, 'Location')
        console.log('creatLocation', item)
        //this.form.items[this.addindex].gpsLat = p.lat
        //this.form.items[this.addindex].gpsLng = p.lng
        item.gpsLat = p.lat
        item.gpsLng = p.lng
      },

      addItemStart(index, type) {
        if (index == 0) {
          if (!(type == 'Pano' || type == 'Image' || type == 'Video')) {
            this.$message({
              message: '第一项不能是无图片项',
              type: 'info',
              zIndex: 9999,
            })
            return;
          }
        }
        if (type == 'Pano') {
          var p = new Promise((resolve, reject) => {
            this.PanoMultipleLibOnSubmit = (files) => {
              resolve(files)
            }
            setTimeout(() => {
              this.$refs.PanoMultipleLib.showDialog();
            }, 1)
          }).then((files) => {
            console.log(files);
            for (var i in files) {
              this.addItem(index + parseInt(i), type, files[i])
            }
            this.PanoMultipleLibOnSubmit = () => { };
            this.addindex = null
          })
          return;
        }
        if (type == 'Location') {
          this.$refs.newLocation.showModal()
          return
        }
        if (type == 'Image' || type == 'File' || type == 'Audio' || type == 'Video') {
          var p = new Promise((resolve, reject) => {
            this.FileMultipleLibOnSubmit = (files) => {
              resolve(files)
            }
            this.FileMultipleLibType = type
            setTimeout(() => {
              this.$refs.FileMultipleLib.showDialog();
            }, 1)
          }).then((files) => {
            console.log(files);
            for (var i in files) {
              this.addItem(index + parseInt(i), type, files[i])
            }
            this.FileMultipleLibType = null;
            this.FileMultipleLibOnSubmit = () => { };
            this.addindex = null
          })
        } else {
          this.addItem(index, type, null)
          this.addindex = null
          if (type == 'Text' || type == 'Link') {
            this.selectedIndex = index
          }
        }
      },
      moveItem(from, to) {
        var item = this.form.items[from]
        if (to < 0 || to > this.form.items.length || item == null) {
          this.$message({
            message: '移动错误',
            type: 'error',
            zIndex: 9999,
          })
          return
        }
        if (to == 0) {
          var type = item.type
          if (type == 'Text' || type == 'Audio' || type == 'Link' || type == 'LinkVideo') {
            this.$message({
              message: '第一项不能是无图片项',
              type: 'info',
              zIndex: 9999,
            })
            return;
          }
        }
        if (from == 0) {
          var type = this.form.items[+from + 1].type
          if (type == 'Text' || type == 'Audio' || type == 'Link' || type == 'LinkVideo') {
            this.$message({
              message: '第一项不能是无图片项',
              type: 'info',
              zIndex: 9999,
            })
            return;
          }
        }
        this.form.items.splice(from, 1)
        this.form.items.splice(to, 0, item)
      },
      removeItem(index) {
        this.form.items.splice(index, 1)
      },
      newItem() {
        return {
          file: null,
          linkItem: null,
          type: null,
          url: '',
          title: '',
          description: '',
          hotspots: [],
          gpsLat: 0,
          gpsLng: 0,
        }
      },
      makeUrlStyle(url) {
        return {
          'background-image': "url('" + url + "')"
        }
      },
      setContextmenu(krpano) {
        var menuXml = `
<krpano>
<contextmenu fullscreen="false" versioninfo="true">
    <item name="fs" caption="全屏浏览"     onclick="set(fullscreen,true);"      showif="fullscreen == false" />
    <item name="ef" caption="退出全屏"     onclick="set(fullscreen,false);"     showif="fullscreen == true" />
    <item name="cc" caption="切换控制模式" onclick="skin_changecontrolmode();"  separator="true" />
    <item name="nv" caption="一般视角"     onclick="skin_view_normal();"        showif="view.vlookatrange == 180" separator="true"      />
    <item name="fv" caption="鱼眼视角"     onclick="skin_view_fisheye();"       showif="view.vlookatrange == 180" devices="flash|webgl" />
    <item name="sv" caption="立体视角"     onclick="skin_view_stereographic();" showif="view.vlookatrange == 180" devices="flash|webgl" />
    <item name="av" caption="构架视角"     onclick="skin_view_architectural();" showif="view.vlookatrange == 180"                       />
    <item name="pv" caption="超广角视角"   onclick="skin_view_pannini();"       showif="view.vlookatrange == 180" devices="flash|webgl" />
    <item name="lp" caption="小行星视角"   onclick="skin_view_littleplanet();"  showif="view.vlookatrange == 180" devices="flash|webgl" />
  </contextmenu>


  <action name="skin_changecontrolmode">
    switch(control.mouse, moveto, drag);
    switch(control.touch, moveto, drag);
  </action>

  <action name="skin_view_look_straight">
    if(view.vlookat LT -80 OR view.vlookat GT +80,
      tween(view.vlookat, 0.0, 1.0, easeInOutSine);
      tween(view.fov,     100, distance(150,0.8));
      );
  </action>

  <action name="skin_view_normal">
    skin_view_look_straight();
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    0.0, distance(1.0,0.5));
  </action>

  <action name="skin_view_fisheye">
    skin_view_look_straight();
    tween(view.architectural, 0.0,  distance(1.0,0.5));
    tween(view.pannini,       0.0,  distance(1.0,0.5));
    tween(view.distortion,    0.35, distance(1.0,0.5));
  </action>

  <action name="skin_view_architectural">
    skin_view_look_straight();
    tween(view.architectural, 1.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    0.0, distance(1.0,0.5));
  </action>

  <action name="skin_view_stereographic">
    skin_view_look_straight();
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    1.0, distance(1.0,0.8));
  </action>

  <action name="skin_view_pannini">
    skin_view_look_straight();
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       1.0, distance(1.0,0.8));
    if(view.distortion LT 0.1,
      tween(view.distortion, 1.0, distance(1.0,0.8));
      );
  </action>

  <action name="skin_view_littleplanet">
    tween(view.architectural, 0.0, distance(1.0,0.5));
    tween(view.pannini,       0.0, distance(1.0,0.5));
    tween(view.distortion,    1.0, distance(1.0,0.8));
    tween(view.fov,           150, distance(150,0.8));
    tween(view.vlookat,        90, distance(100,0.8));
    add(new_hlookat, view.hlookat, 123.0);
    tween(view.hlookat, get(new_hlookat), distance(100,0.8));
  </action>
</krpano>
`
        krpano.call(`loadxml(${menuXml},null,MERGE|KEEPSCENES,null)`)
      },

      cancel() {
        if (confirm("确定退出吗，未保存的内容将丢失")) {
          this.Close()
        } else {
          return
        }
      },
      Close() {
        this.cantleave = false
        setTimeout(() => {
          this.$router.push({ name: 'BlogList' })
        }, 1)
      },
      Create() {
        this.$v.$touch()
        if (this.$v.$invalid) {
          if (!this.$v.form.items.required) {
            this.$message({
              message: '至少添加一项',
              type: 'info',
              zIndex: 9999,
            })
          } else if (!this.$v.form.items.maxLength) {
            this.$message({
              message: `最多只能添加 ${this.$v.form.items.$params.maxLength.max} 项`,
              type: 'info',
              zIndex: 9999,
            })
          }
          return
        }
        this.updateing = true;
        this.$axios.post('/Api/My/Blogs', this.form)
          .then(res => {
            this.$message({
              message: '提交成功',
              type: 'info',
              zIndex: 9999,
            })
            this.Close()
            this.updateing = false;
          }).catch(e => {
            this.updateing = false;
          })
      },
      Update(guid) {
        this.$v.$touch()
        if (this.$v.$invalid) {
          if (!this.$v.form.items.required) {
            this.$message({
              message: '至少添加一项',
              type: 'info',
              zIndex: 9999,
            })
          } else if (!this.$v.form.items.maxLength) {
            this.$message({
              message: `最多只能添加 ${this.$v.form.items.$params.maxLength.max} 项`,
              type: 'info',
              zIndex: 9999,
            })
          }
          return
        }
        this.updateing = true;
        this.$axios.put('/Api/My/Blogs/' + guid, this.form)
          .then(res => {
            this.$message({
              message: '提交成功',
              type: 'info',
              zIndex: 9999,
            })
            this.Close()
            this.updateing = false;
          }).catch(e => {
            this.updateing = false;
          })
      },
      loadTags() {
        var tagtype = '博文';
        this.$axios.get('/Api/My/Tags/All', { params: { type: tagtype } })
          .then((response) => {
            //console.log(response)
            this.privateTags = response.data
          })
          .catch((error) => {
            console.log(error)
          })
        this.$axios.get('/Api/Tags/All', { params: { type: tagtype } })
          .then((response) => {
            //console.log(response)
            this.publicTags = response.data
          })
          .catch((error) => {
            console.log(error)
          })
      },
      tagsCheckOnChange(list) {
        console.log(list)
        this.form.tags = [...list]
      },
      showFileLibDialog(index) {
        this.selectedIndex = index;
        this.$refs.FileMultipleLib.showDialog();
      },
      FileLibSelected(select) {
        this.form.items[this.selectedIndex].file = select;
        this.selectedIndex = null;
      },
      showAudioLibDialog() {
        this.$refs.AudioLib.showDialog();
      },
      AudioLibSelected(select) {
        this.form.audio = select;
      },
    },
    computed: {
      tags: function () {
        return [...this.publicTags, ...this.privateTags]
      }
    },

    watch: {
    },
  }
</script>
<style>
  .blogedit p {
    margin-top: 0;
    margin-bottom: 0;
  }
</style>

<style scoped>
  .selected {
    background-color: #0006
  }

  .blogedit .itemicon {
    width: 70px;
    height: 70px;
    background-color: gray;
    border-radius: 4px;
    overflow: hidden;
  }

    .blogedit .itemicon > .imgdiv {
      width: 100%;
      height: 100%;
      background-image: url('');
      background-size: cover;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
    }

  .blogedit .itembody {
    width: calc(100% - 55px);
  }

  .blogedit .location .modalwbox {
    width: 100%
  }

  .blogedit .Modal {
    z-index: 1050;
  }
</style>
